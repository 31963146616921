import {Button, Form, Input, InputNumber, Modal} from 'antd';
import React, {FormEvent, useState} from 'react';
import {Client} from "@typings/index";
import {AxiosError} from "axios/index";
import {generateClientToken} from "../../../../services/UserService";

interface CreateAppTokenModalProps {
    open: boolean;
    onClose: () => void;
    client: Client;
}

export function CreateAppTokenModal(props: CreateAppTokenModalProps) {
    const [generatedToken, setGeneratedToken] = useState<string | null>(null);
    const [tokenName, setTokenName] = useState<string | undefined>();
    const [duration_h, setDuration_h] = useState<number | null>(null);
    const [tokenGenerationDisabled, setTokenGenerationDisabled] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);

    const createToken = (e) => {
        e.preventDefault();
        setLoading(true);
        generateClientToken(tokenName, props.client.id, duration_h)
            .then(
                (success) => {
                    console.log("revokeClientToken response", success);
                    setTokenName(undefined);
                    setDuration_h(null);
                    setTokenGenerationDisabled(true);
                    setGeneratedToken(success.access_token)
                },
                (error: AxiosError) => {
                    console.log("cannot revokeClientToken", error.response.data)
                }
            )
            .finally(() => setLoading(false));
    }

    function onFormChange(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const {value, name} = event.target as HTMLInputElement
        if (name === "duration_h") {
            setDuration_h(Number(value));
        } else {
            setTokenName(value);
        }
    }

    const onChange = (value: number) => {
        setDuration_h(value);
    };

    const onClose = (e: React.MouseEvent) => {
        e.preventDefault();
        props.onClose();
    };

    return (
        <Modal key={"new-app-token-modal"} title="Tworzenie nowego tokena" open={props.open}
               closable={false} destroyOnClose={true}
               onCancel={onClose} cancelText={"Anuluj"}
               footer={[
                   <Button key={"new-app-token-bnt-close"} type="primary" onClick={onClose}>
                       Zamknij
                   </Button>
               ]}
        >
            <Form layout="horizontal" onChange={onFormChange}>
                <Form.Item label="Ważność tokena" labelCol={{span: 6}} style={{ width: '100%' }}>
                    <InputNumber name={"duration_h"} placeholder="Wprowadź ważność tokena w godzinach"
                                 disabled={tokenGenerationDisabled} style={{ width: '100%' }}
                                 min={0} defaultValue={null} onChange={onChange}/>
                </Form.Item>
                <Form.Item label="Nazwa tokena" labelCol={{span: 6}} style={{ width: '100%' }} required={true}>
                    <Input name={"name"} placeholder="Wprowadź nazwę identyfikacyjną dla tokena"
                           disabled={tokenGenerationDisabled}
                           suffix={
                               <Button size={"small"} type="primary" style={{backgroundColor: "green"}}
                                       onClick={createToken}
                                       disabled={tokenGenerationDisabled || tokenName === undefined}>
                                   Generuj
                               </Button>
                           }
                    />
                </Form.Item>
                {generatedToken && <Form.Item label="Token">
                  <Input.Password contentEditable={false} value={generatedToken}/>
                </Form.Item>}
            </Form>
        </Modal>
    );
};
