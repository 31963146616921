import {Button, Checkbox, Spin, Table, Typography} from 'antd';
import React, {MouseEvent, useEffect, useState} from 'react';
import {Account} from '../../types';
import {useNavigate} from "react-router-dom";
import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import AddAccountModal from "./AddAccountModal";
import {getAccountList} from "../../services/AccountService";
import {AxiosError} from "axios/index";
import RemoveAccountModal from "./RemoveAccountModal";

export default function AccountList() {
    const [accountList, setAccountList] = useState<Array<Account>>([]);
    const [createNewAccountModalOpen, setCreateNewAccountModalOpen] = useState<boolean>(false);
    const [removeAccountModalOpen, setRemoveAccountModalOpen] = useState<boolean>(false);
    const [selectedAccount, setSelectedAccount] = useState<Account>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    function updateAccountList() {
        getAccountList()
            .then(
                (success: Array<Account>) => {
                    console.log(success);
                    setAccountList(success);
                },
                (error: AxiosError) => {
                    console.log("cannot get account list", error.response.data)
                }
            )
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        updateAccountList();
    }, [])

    const openNewAccountModal = () => {
        setCreateNewAccountModalOpen(true);
    }

    const onCloseNewAccountModal = () => {
        updateAccountList();
        setCreateNewAccountModalOpen(false)
    }

    const openRemoveAccountModal = (e: any, account: Account) => {
        e.preventDefault();
        setSelectedAccount(account);
        setRemoveAccountModalOpen(true);
    };

    const closeRemoveAccountModal = () => {
        updateAccountList();
        setRemoveAccountModalOpen(false);
        setSelectedAccount(undefined);
    }


    return (
        <Spin key={"account-list-spin"} tip="Loading..." spinning={loading}>
            <Typography.Title level={2}>
                Lista kont w systemie
            </Typography.Title>
            <Table
                pagination={false}
                key={"account-list-table"}
                size={"small"}
                rowKey={record => `account-${record.id}-${record.name}`}
                locale={{emptyText: 'Brak danych'}}
                columns={[
                    {
                        title: 'Nazwa skrócona',
                        dataIndex: 'name',
                        key: 'name',
                    },
                    {
                        title: 'Pełna nazwa',
                        dataIndex: 'full_name',
                        key: 'full_name',
                    },
                    {
                        title: 'Aktywne',
                        dataIndex: 'active',
                        key: 'x',
                        render: (_value: any, record: Account, _index) => <Checkbox checked={record.active}/>,
                    },
                    {
                        title: '',
                        dataIndex: '',
                        key: 'x',
                        render: (_value: any, account: Account, _index) => <>
                            <Button className="link-secondary m-1 p-2 rounded-2"
                                    onClick={() => navigate(`/account/${account.id}`)}>
                              <EditOutlined className={'d-flex m-auto'} />
                            </Button>
                            <Button className="link-danger m-1 p-2 rounded-2"
                                    onClick={e => openRemoveAccountModal(e, account)}>
                              <DeleteOutlined className={'d-flex m-auto'} />
                            </Button>
                        </>
                    },
                ]}
                dataSource={[...accountList]}
            />
            <Button className="link-primary m-1" icon={<PlusOutlined/>} onClick={openNewAccountModal}
                    style={{float: "right",}}>
                Dodaj
            </Button>
            {createNewAccountModalOpen &&
              <AddAccountModal open={createNewAccountModalOpen} onClose={onCloseNewAccountModal}/>}

            {removeAccountModalOpen &&
              <RemoveAccountModal open={removeAccountModalOpen} onClose={closeRemoveAccountModal}
                                  account={selectedAccount}/>}
        </Spin>
    );
};
