import {Button, Modal, Result, Space, Spin} from 'antd';
import React, {useState} from 'react';
import {Account, Client} from "@typings/index";
import {AxiosError} from "axios/index";
import {removeClient} from "../../../services/UserService";

interface RemoveAppModalProps {
    open: boolean;
    onClose: () => void;
    client: Client;
}

export function RemoveClientModal(props: RemoveAppModalProps) {
    const [loading, setLoading] = useState<boolean>(false);
    const confirmRemoval = () => {
        setLoading(true)
        removeClient(props.client.id)
            .then(
                (success: Account) => {
                    console.log("remove client response s", success);
                    setLoading(false)
                    props.onClose();
                },
                (error: AxiosError) => {
                    console.log("remove client response err", error.response.data)
                }
            );

    }
    const exitRemoval = () => {
        props.onClose();
    }

    return props.open && (
        <Spin key={"remove-client-spin"} tip="Loading..." spinning={loading}>
            <Modal key={"remove-app-modal"} open={props.open} closable={false} destroyOnClose={true} footer={null}>
                <Result
                    status={"warning"}
                    title={`Czy na pewno chcesz usunąć: ${props.client.name} ?`}
                    subTitle={"Operacji nie da się cofnąć"}
                    extra={
                        <Space key={"remove-client-modal-space"}>
                            <Button type="primary" onClick={exitRemoval}>NIE</Button>
                            <Button danger type="primary" onClick={confirmRemoval}>Tak</Button>
                        </Space>
                    }
                />
            </Modal>
        </Spin>
    );
};
