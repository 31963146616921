import {BrowserRouter} from 'react-router-dom';
import App from './App';
import {RootProvider} from './context/rootContext';
import './index.css';
import {createRoot} from 'react-dom/client'
import { ConfigProvider } from 'antd'

const root = createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <RootProvider>
      <ConfigProvider theme={{ token: { colorPrimary: '#FD5E34' } }}>
        <App />
      </ConfigProvider>
    </RootProvider>
  </BrowserRouter>
);
