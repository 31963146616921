import {RootContext} from '../../context/rootContext';
import React, {useContext, useState} from 'react';
import {LogoutOutlined, TeamOutlined} from '@ant-design/icons';
import type {MenuProps} from 'antd';
import {Layout, Menu, theme} from 'antd';
import {useNavigate} from "react-router-dom";

const {Header, Content, Footer, Sider} = Layout;
type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
    } as MenuItem;
}

const items: MenuItem[] = [
    getItem("Konta", "accounts", <TeamOutlined/>),
    getItem("Wyloguj", "logout", <LogoutOutlined/>),
];

export default function Main(props) {
    const navigate = useNavigate();
    const {isAuthenticated} = useContext(RootContext);
    const [collapsed, setCollapsed] = useState(true);
    const {
        token: {colorBgContainer},
    } = theme.useToken();

    const onMenuItemClick = (event) => {
        console.log(event)
        const {key} = event
        if (key === "logout") {
            sessionStorage.clear();
            window.location.reload();
        } else {
            navigate(`/${key}`);
        }
    };

    return (
      <Layout style={{ minHeight: "100vh" }}>
          {isAuthenticated &&
            <Sider className={"position-fixed"} style={{ height: "100vh", zIndex: 1000 }} collapsible
                   collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                <div className={'text-center'} style={{ height: 32, margin: 16, color: "white" }}>Pointo AMS</div>
                <Menu theme="dark" defaultSelectedKeys={["accounts"]} mode="inline" items={items}
                      onClick={onMenuItemClick} />
            </Sider>}
          <Layout>
              <Content className={`my-2 ${isAuthenticated ? '' : 'align-content-center'}`} style={{ marginLeft: isAuthenticated ? "96px" : "0" }}>
                  {props.children}
              </Content>
              <Footer className={'py-2'} style={{textAlign: 'center'}}>e-point Polska Sp. z o.o. ©2023 </Footer>
          </Layout>
      </Layout>
    );
}
