import {Button, Modal, Result, Space, Spin} from 'antd';
import React, {useState} from 'react';
import {Account, Client} from "@typings/index";
import {AxiosError} from "axios/index";
import {removeAccount} from "../../services/AccountService";

interface RemoveAccountModalProps {
    open: boolean;
    onClose: () => void;
    account: Account;
}

export default function RemoveAccountModal(props: RemoveAccountModalProps) {
    const [loading, setLoading] = useState<boolean>(false);
    const confirmRemoval = () => {
        setLoading(true)
        removeAccount(props.account.id)
            .then(
                (success: Account) => {
                    console.log("remove account response s", success);
                    setLoading(false)
                    props.onClose();
                },
                (error: AxiosError) => {
                    console.log("remove account response err", error.response.data)
                }
            );

    }
    const exitRemoval = () => {
        props.onClose();
    }

    return props.open && (
        <Spin key={"remove-account-spin"} tip="Loading..." spinning={loading}>
            <Modal key={"remove-app-modal"} open={props.open} closable={false} destroyOnClose={true} footer={null}>
                <Result
                    status={"warning"}
                    title={`Czy na pewno chcesz usunąć: ${props.account.name} ?`}
                    subTitle={"Operacji nie da się cofnąć"}
                    extra={
                        <Space key={"remove-account-modal-space"}>
                            <Button type="primary" onClick={exitRemoval}>NIE</Button>
                            <Button danger type="primary" onClick={confirmRemoval}>Tak</Button>
                        </Space>
                    }
                />
            </Modal>
        </Spin>
    );
};
