import {Button, Col, message, Row, Space, Switch, Table, Typography} from "antd";
import {Client} from "@typings/index";
import {AxiosError} from "axios";
import React, {useEffect, useState} from "react";
import {getAvailableClientPermissions, SystemPermissions} from "../../../../services/PermissionService";
import {updateClientPermission} from "../../../../services/UserService";
import {SaveOutlined} from "@ant-design/icons";

interface EditUserPermissionsProps {
    client: Client;
    updateClientDetails: () => void;
}

export function EditUserPermissions(props: EditUserPermissionsProps) {
    const [loading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [allPerms, setAllPerms] = useState<Array<SystemPermissions>>([]);
    const [updatedClient, setUpdatedClient] = useState<Client>(props.client);


    function getAllPermissions() {
        setLoading(true);
        getAvailableClientPermissions()
            .then(
                (success: Array<SystemPermissions>) => {
                    console.log("get all permissions response", success);
                    setAllPerms(success);
                },
                (error: AxiosError) => {
                    console.log("cannot get all permissions", error.response.data)
                }
            )
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        getAllPermissions();
    }, [])

    const onEditModeChange = (checked: boolean, _event: React.MouseEvent<HTMLButtonElement>) => {
        setEditMode(checked);
        setUpdatedClient(props.client);
    }

    const editClientPermissions = (checked: boolean, permission: SystemPermissions) => {
        let newPerm: string[];
        if (checked) {
            newPerm = updatedClient.access_codes.concat(permission.code);
        } else {
            newPerm = updatedClient.access_codes.filter(perm => perm !== permission.code);
        }
        setUpdatedClient({...updatedClient, access_codes: newPerm})
    }

    const onSave = () => {
        setLoading(true);
        updateClientPermission(props.client.id, updatedClient)
            .then(
                (success) => {
                    console.log("updateClient response", success);
                    message.success('Pomyślnie zaktualizowano!');
                    props.updateClientDetails();
                },
                (error: AxiosError) => {
                    console.log("cannot updateClient", error.response.data);
                    setUpdatedClient(props.client);
                }
            )
            .finally(() => {
                setLoading(false);
                setEditMode(false);
            });
    }


    return (
        <>
            <Row justify="space-between" align="middle" style={{marginBottom: 16}}>
                <Col span={9}>
                    <Space>
                        <Switch checkedChildren="Anuluj" unCheckedChildren="Edytuj" checked={editMode}
                                onChange={onEditModeChange}/>
                        <Typography.Title level={3} style={{marginBottom: 0}}>
                            Edycja uprawnień
                        </Typography.Title>
                    </Space>
                </Col>
                <Col span={3}>
                    <Button className="link-primary m-1" disabled={!editMode} style={{float: "right"}}
                            icon={<SaveOutlined/>} onClick={onSave}>Zapisz</Button>
                </Col>
            </Row>

            {allPerms.length > 0 &&
              <>
                <Table
                  loading={loading}
                  key={"user-perm-list-table"}
                  size={"small"}
                  pagination={false}
                  rowKey={record => record.code}
                  locale={{emptyText: 'Brak danych'}}
                  columns={[
                      {
                          title: 'Kod',
                          dataIndex: 'code',
                          key: 'code',
                      },
                      {
                          title: 'Opis',
                          dataIndex: 'description',
                          key: 'description',
                          render: (_value: any, record: SystemPermissions, _index) => <>
                              {record.description}
                          </>,
                      },
                      {
                          title: 'Aktywny',
                          dataIndex: 'active',
                          key: 'active',
                          render: (_value: any, record: SystemPermissions, _index) => <>
                              {record.active ?? "-"}
                          </>,
                      },
                      {
                          title: '',
                          dataIndex: '',
                          key: 'x',
                          render: (_value: any, record: SystemPermissions, _index) => <>
                              <Switch checkedChildren="tak" unCheckedChildren="nie"
                                      checked={updatedClient.access_codes.includes(record.code)} disabled={!editMode}
                                      onChange={(checked, _event) => editClientPermissions(checked, record)}/>
                          </>,
                      },
                  ]}
                  dataSource={[...allPerms]}
                />
              </>
            }
        </>
    )
}
