import {Button, Checkbox, Form, Input, message, Modal, Popover, Space, Spin} from 'antd';
import React, {FormEvent, useState} from 'react';
import {LockOutlined, SaveOutlined} from "@ant-design/icons";
import {Account, Client} from "@typings/index";
import {createClient} from "../../../services/UserService";

interface AddClientModalProps {
    open: boolean
    onClose: () => void
    account: Account
    clientType: "user" | "application";
}

const appType = {
    "user": "użytkownika",
    "application": "aplikacji",
}

export function AddClientModal(props: AddClientModalProps) {
    const [passErr, setPassErr] = useState({error: false, message: ""});
    const [emErr, setEmErr] = useState({error: false, message: ""});
    const [loading, setLoading] = useState<boolean>(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [newClient, setNewClient] = useState<Client>({
        account_id: props.account.id,
        active: false,
        name: "",
        client_type: props.clientType,
    });

    function onFormChange(event: FormEvent<HTMLFormElement>) {
        const {name, value, checked, type} = event.target as HTMLInputElement
        if (name !== "organisation") {
            if (name === "password") {
                validatePassword(value)
            } else if (name === "email") {
                validateEmail(value)
            }
            setNewClient({
                ...newClient,
                [name]: type === "checkbox" ? checked : value
            })
        }
    }

    const validatePassword = (value: string) => {
        let error = false;
        let message = "";
        if (value.length < 8) {
            error = true
            message = "Hasło jest za krótkie (min 8 znaków)."
        }
        console.log("val email", message)
        setPassErr({error, message})
    }

    const validateEmail = (value: string) => {
        let error = false;
        let message = "";
        if (value.length <= 0) {
            error = true
            message = "Email jest wymagany"
        } else {
            if (!value.includes("@")) {
                error = true
                message = "Hasło jest za krótkie (min 8 znaków)."
            } else {
                const emailParts = value.split("@")
                const emailFirstPart = emailParts[0]
                const emailDomain = emailParts[1]
                if (emailFirstPart.length <= 0) {
                    error = true
                    message = "Hasło jest za krótkie (min 8 znaków)."
                } else if (emailDomain.length < 3) {
                    error = true
                    message = "Zła domena"
                } else if (emailDomain.split(".").length < 2) {
                    error = true
                    message = "Zła domena"
                } else if (emailDomain.split(".")[0]?.length <= 0 || emailDomain.split(".")[1]?.length <= 0) {
                    error = true
                    message = "Zła domena"
                }
            }
        }
        // console.log("val email", message)

        setEmErr({error, message})
    }

    const onClientSave = (e: React.MouseEvent) => {
        e.preventDefault();
        setLoading(true);
        createClient(newClient)
            .then(
                success => {
                    console.log("client create response success", success);
                    setLoading(false);
                    props.onClose();
                },
                error => {
                    console.log("client create response error", error.response.data.message);
                    message.error(error.response.data.message || 'Błąd tworzenia użytkownika.');
                    setLoading(false);
                }
            )
    };

    const onCancel = (e: React.MouseEvent) => {
        e.preventDefault();
        props.onClose();
    };

    return (
        <Spin key={"add-client-spin"} tip="Loading..." spinning={loading}>
            <Modal key={"add-client-modal"} title={`Dodawanie ${appType[props.clientType]}`} open={props.open}
                   closable={false}
                   footer={[
                       <Space key={"add-client-modal-space"}>
                           <Button size={"small"} icon={<LockOutlined/>} type="primary"
                                   onClick={onCancel}>Anuluj</Button>
                           <Button size={"small"} icon={<SaveOutlined/>} type="primary"
                                   onClick={onClientSave}>Dodaj</Button>
                       </Space>
                   ]}
            >
                <Form key={"add-client-form"} layout={"horizontal"} onChange={onFormChange}
                      suppressContentEditableWarning={true}>
                    <Form.Item label="Nazwa" labelCol={{span: 4}} wrapperCol={{span: 14}}>
                        <Input name={"name"} value={newClient.name}/>
                    </Form.Item>
                    {props.clientType === "user" &&
                      <>
                        <Form.Item label="Email" labelCol={{span: 4}} wrapperCol={{span: 14}} required={true}>
                          <Popover content={emErr.message} open={emErr.error} placement={"top"}>
                            <Input name={"email"} status={emErr.error ? 'error' : undefined} value={newClient.email}/>
                          </Popover>
                        </Form.Item>
                        <Form.Item label="Hasło" labelCol={{span: 4}} wrapperCol={{span: 14}} required={true}>
                          <Popover content={passErr.message} open={passErr.error} placement={"bottom"}>
                            <Input.Password name={"password"} value={newClient.password}
                                            status={passErr.error ? 'error' : undefined}
                                            visibilityToggle={{
                                                visible: passwordVisible,
                                                onVisibleChange: setPasswordVisible
                                            }}/>
                          </Popover>
                        </Form.Item>
                      </>
                    }
                    <Form.Item label="Active" labelCol={{span: 4}} wrapperCol={{span: 14}}>
                        <Checkbox name={"active"} checked={newClient.active}/>
                    </Form.Item>
                </Form>
            </Modal>
        </Spin>
    );
}
