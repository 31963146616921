import {Button, Checkbox, Form, Input, message, Modal, Space} from 'antd';
import React, {FormEvent, useState} from 'react';
import {LockOutlined, SaveOutlined} from "@ant-design/icons";
import {Account} from "@typings/index";
import {createAccount} from "../../services/AccountService";
import {AxiosError} from "axios";

interface AddAccountModalProps {
    open: boolean
    onClose: () => void
}

export default function AddAccountModal(props: AddAccountModalProps) {
    const [loading, setLoading] = useState<boolean>(true);
    const [newAccount, setNewAccount] = useState<Account>({
        active: false,
        name: "",
        full_name: ""
    });

    function onFormChange(event: FormEvent<HTMLFormElement>) {
        const {name, value, checked, type} = event.target as HTMLInputElement
        setNewAccount({
            ...newAccount,
            [name]: type === "checkbox" ? checked : value
        })
    }

    const onAccountSave = (e: React.MouseEvent) => {
        e.preventDefault();
        createAccount(newAccount)
            .then(
                (success) => {
                    console.log("createAccount success", success);
                    message.success('Pomyślnie zaktualizowano');
                    setLoading(false)
                    props.onClose();
                },
                (error: AxiosError) => {
                    console.log("cannot save account", error.response.data)
                    setLoading(false)
                    // @ts-ignore
                    message.error(error.response.data.message || 'Nie można utworzyć konta');
                }
            );
    };

    const onCancel = (e: React.MouseEvent) => {
        e.preventDefault();
        props.onClose();
    };

    return (
        <Modal key={"add-account-modal"} title="Dodawanie nowego konta" open={props.open} closable={false}
               destroyOnClose={true} onCancel={onCancel} cancelText={"Anuluj"}
               footer={[
                   <Space>
                       <Button size={"small"} icon={<LockOutlined/>} type="primary" onClick={onCancel}>Anuluj</Button>
                       <Button size={"small"} icon={<SaveOutlined/>} type="primary"
                               onClick={onAccountSave}>Zapisz</Button>
                   </Space>
               ]}
        >
            <Form key={"account-add-form"} layout={"horizontal"} onChange={onFormChange}
                  suppressContentEditableWarning={true}>
                <Form.Item label="Nazwa skrócona" labelCol={{span: 7}} wrapperCol={{span: 14}}>
                    <Input name={"name"} value={newAccount.name}/>
                </Form.Item>
                <Form.Item label="Pełna nazwa" labelCol={{span: 7}} wrapperCol={{span: 14}}>
                    <Input name={"full_name"} value={newAccount.full_name}/>
                </Form.Item>
                <Form.Item label="Aktywne" labelCol={{span: 7}} wrapperCol={{span: 14}}>
                    <Checkbox name={"active"} checked={newAccount.active}/>
                </Form.Item>
            </Form>
        </Modal>
    );
};
