import {Button, Col, DatePicker, message, Row, Space, Spin, Table, Typography} from 'antd';
import React, {useEffect, useState} from 'react';
import {Account} from "@typings/index";
import {UndoOutlined} from "@ant-design/icons";
import {AxiosError} from "axios";
import {ColumnsType} from "antd/es/table";
import {getAccountStats} from "../../../services/AccountService";
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/pl_PL';
import plPL from "antd/lib/locale/pl_PL"

require('dayjs/locale/pl')
dayjs.locale(plPL.locale)


const {Text} = Typography;
const monthCharFormat = 'MMMM';
const monthNumFormat = 'MM';
const yearNumFormat = 'YYYY';

interface StatsListProps {
    account: Account;
}

interface AccountStatAppDetails {
    id: number;
    name: string;
    total_in: number;
    total: number;
}

interface AccountStatServiceDetails {
    id: number;
    name: string;
    total_in: number;
    total: number;
    total_apps: number;
    parent: boolean;
    apps: Array<AccountStatAppDetails>;
}

interface AccountStatData {
    id: number;
    name: string;
    total_in: number;
    total: number;
    total_apps: number;
    parent: boolean;
    apps: Array<AccountStatAppDetails>;
}

interface DateSelected {
    month: number;
    year: number;
}

export function AccountApiStats(props: StatsListProps) {
    const [loading, setLoading] = useState<boolean>(true);
    const [dateSelected, setDateSelected] = useState<DateSelected>({
        month: Number(dayjs().locale('pl').format(monthNumFormat)),
        year: Number(dayjs().locale('pl').format(yearNumFormat)),
    });
    const [accountStats, setAccountStats] = useState<Array<AccountStatData>>(null);

    useEffect(() => {
        getStats();
    }, [dateSelected, props.account])

    const getStats = () => {
        setLoading(true);
        getAccountStats(props.account.id, dateSelected.month, dateSelected.year)
            .then(
                (success) => {
                    setAccountStats(success);
                },
                (error: AxiosError) => {
                    console.log("cannot get AccountStats", error)
                    // @ts-ignore
                    message.error(error?.response?.data?.message || 'Błąd pobierania danych :/ ');
                }
            )
            .finally(() => setLoading(false));
    };

    const onDateChangeE = (date: dayjs.Dayjs) => {
        setDateSelected({
            month: Number(date.toDate().toLocaleString("pl", {month: "numeric"})),
            year: Number(date.toDate().toLocaleString("pl", {year: "numeric"})),
        })
    };


    function getColumns(): ColumnsType<AccountStatServiceDetails> {
        const totalApps = accountStats[0].total_apps;
        let apps = []
        if (totalApps > 0 && accountStats !== null) {
            apps = accountStats[0].apps;
        }
        return [
            {
                title: 'Nazwa',
                dataIndex: 'name',
                key: 'name',
                width: 200,
                fixed: 'left',
                children: [
                    {
                        title: 'Nazwa usługi',
                        dataIndex: 'name',
                        key: 'name',
                        width: 200,
                        fixed: 'left',
                        render: (value: any, record: AccountStatData, _index) => {
                            return (
                                <span style={{
                                    fontWeight: record.parent ? "bolder" : "normal",
                                    paddingLeft: record.parent === true ? "0" : "30px",
                                }}>
                                    {value}
                                </span>
                            )
                        },
                    }
                ]
            },
            ...apps.map((ap: AccountStatData, idx) => {
                return {
                    title: `Aplikacja ${ap.name}`,
                    dataIndex: "apps[" + (ap.id - 1) + "].app",
                    key: "key-" + ap.id,
                    children: [
                        {
                            title: 'Liczba zapytań',
                            dataIndex: "apps[" + (ap.id - 1) + "].app.total",
                            key: "apps[" + (ap.id - 1) + "].app.total",
                            width: 100,
                            align: 'center',
                            render: (_value2: any, _record2: AccountStatData, _index) => {
                                let a = 0
                                if (_record2.parent){
                                    accountStats[_index].apps.forEach(asd => {
                                        a += asd.total
                                    })
                                    console.log("Liczba zapytań", a, accountStats[_index], _record2)
                                }
                                return (<span
                                    style={{
                                        fontWeight: _record2.parent ? "bolder" : "normal",
                                    }}>{accountStats[_index].apps[idx].total}</span>)
                            }
                        },
                        {
                            title: 'Liczba zapytań w obszarze świadczenia usługi',
                            dataIndex: "apps[" + (ap.id - 1) + "].app.total_in",
                            key: "apps[" + (ap.id - 1) + "].app.total_in",
                            width: 100,
                            align: 'center',
                            render: (_value2: any, _record2: AccountStatData, _index) => {
                                return (<span
                                    style={{
                                        fontWeight: _record2.parent ? "bolder" : "normal",
                                    }}>{accountStats[_index].apps[idx].total_in}</span>)
                            }
                        }
                    ]
                }
            })
        ]
    }

    return (
      <>
          <Row className={"p-2"} justify="center" align="middle">
              <DatePicker
                size={"large"}
                allowClear={false}
                locale={locale}
                defaultValue={dayjs().locale("pl")}
                format={monthCharFormat}
                onChange={onDateChangeE}
                picker="month" />
          </Row>
          <Row className={"p-2"} justify="space-between" align="middle">
              <Col span={9}>
                  <Space>
                      <Typography.Title level={3} style={{ marginBottom: 0 }}>
                          Szczegóły zużycia zapytań API
                      </Typography.Title>
                  </Space>
              </Col>
              <Col span={3}>
                  <Button disabled={loading} className="link-primary m-1" icon={<UndoOutlined />}
                          style={{ float: "right" }} onClick={getStats}>
                      Odśwież
                  </Button>
              </Col>
          </Row>
          {
              !loading && accountStats && (
                <Table className={"p-2 w-100"}
                       bordered
                       loading={loading}
                       scroll={{ x: 1200, y: 400 }}
                       key={"account-stats-table"}
                       size={"small"}
                       pagination={false}
                       rowKey={record => `stat-${record.id}-${record.name}`}
                       locale={{ emptyText: "Brak danych" }}
                       columns={getColumns()}
                       dataSource={[...accountStats]}
                       summary={(tableData) => {
                           const apps = tableData[0].apps;
                           let totalBillable = 0;

                           return (
                             <Table.Summary fixed>
                                 <Table.Summary.Row style={{ textAlign: "center" }}>
                                     <Table.Summary.Cell index={0}>Suma zapytań</Table.Summary.Cell>

                                     {apps.map((_ap: AccountStatData, idx) => {
                                         let total = 0;
                                         let totalIn = 0;
                                         tableData.forEach((row) => {
                                             if (row.parent) {
                                                 total += row.apps[idx].total;
                                                 totalIn += row.apps[idx].total_in;
                                             }
                                         });
                                         totalBillable += totalIn;

                                         return (
                                           <>
                                               <Table.Summary.Cell index={idx + 1}>
                                                   <Text>{total}</Text>
                                               </Table.Summary.Cell>
                                               <Table.Summary.Cell index={idx + 2}>
                                                   <Text>{totalIn}</Text>
                                               </Table.Summary.Cell>
                                           </>
                                         );
                                     })}
                                 </Table.Summary.Row>
                                 <Table.Summary.Row style={{ textAlign: "center" }}>
                                     <Table.Summary.Cell index={0}>Suma całkowita (do rozliczenia)</Table.Summary.Cell>
                                     <Table.Summary.Cell index={1}>
                                         <Text style={{ fontWeight: "bolder" }}>{totalBillable}</Text>
                                     </Table.Summary.Cell>
                                 </Table.Summary.Row>
                             </Table.Summary>
                           );
                       }}
                />
              )
          }

          {loading && <Row className={"p-2 mx-auto gap-4"} justify="center" align="middle"><Spin />Pobieranie danych</Row>}
      </>
    );
}
