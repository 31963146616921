import {createContext, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Account, AppToken, Client} from "../types";
import {UserLoginSuccess} from "../services/UserService";

export type RootContextType = {

    isAuthenticated: boolean;
    authenticate: (data: UserLoginSuccess) => void;

}

const defaultContext: RootContextType = {
    isAuthenticated: false,
    authenticate: (_data: UserLoginSuccess) => {
    },
}
export const RootContext = createContext<RootContextType>(defaultContext);


export const RootProvider = (props) => {

    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(defaultContext.isAuthenticated);
    // const [accountList, setAccountList] = useState(defaultContext.accountList);
    const timerId = useRef(null);
    // const [cookies, _setCookies] = useState(new Cookies());

    // const startTimer = () => {
    //     timerId.current = setInterval(() => {
    //         if (validateAuth()) {
    //             timerId.current && clearInterval(timerId.current);
    //             sessionStorage.clear();
    //             // setIsAuthenticated(false)
    //             navigate('/');
    //         }
    //         return () => {
    //             timerId.current && clearInterval(timerId.current);
    //         }
    //
    //     }, 1000)
    // }
    //
    // useEffect(() => {
    //     if (sessionStorage.getItem("A") === "true") {
    //         startTimer();
    //         setIsAuthenticated(true)
    //     } else {
    //         navigate('/');
    //     }
    // }, [navigate, startTimer])

    useEffect(() => {
        if (isAuthenticated && window.location.pathname === "/") {
            navigate('/accounts');
        }
    }, [navigate, isAuthenticated])

    // useEffect(() => {
    //     if (isAuthenticated) {
    //         setTimeout(() => {
    //             getAccountList()
    //                 .then(
    //                     (success: Array<Account>) => {
    //                         console.log(success);
    //                         setAccountList(success);
    //                     },
    //                     (error: AxiosError) => {
    //                         console.log("cannot get account list", error.response.data)
    //                     }
    //                 );
    //         }, 1000)
    //     }
    // }, [isAuthenticated])

    const isTokenValid = () => {
        const now = new Date(Date.now());
        const exp = new Date(sessionStorage.getItem("exp"));
        // @ts-ignore
        const diffTime = exp - now;
        console.log(diffTime)
        return diffTime > 0
    }

    useEffect(() => {
        if (!isTokenValid()) {
            timerId.current && clearInterval(timerId.current);
            sessionStorage.clear();
            navigate('/');
        } else {
            setIsAuthenticated(true)
        }
    }, [])

    const authenticate = (data: UserLoginSuccess) => {
        setIsAuthenticated(true);
        sessionStorage.setItem("exp", new Date(Date.now() + data.valid_time * 1000).toString());
        sessionStorage.setItem("A", "true");
        sessionStorage.setItem("T", data.access_token);
        sessionStorage.setItem("F", data.refresh_token);
        navigate('/accounts');
    };

    return (
        <RootContext.Provider
            value={{
                isAuthenticated,
                authenticate,
            }}>
            {props.children}
        </RootContext.Provider>
    );
};