import 'bootstrap/dist/css/bootstrap.min.css';
import {Route, Routes} from 'react-router-dom';
import './App.css';
import NotFound from './pages/404/404';
import Auth from './pages/auth/Auth';
import Main from './pages/main/Main';
import AccountList from "./pages/account/AccountList";
import AccountDetails from "./pages/account/AccountDetails";
import {ClientDetails} from "./pages/account/client";


function App() {
    return (
      <Main>
          <Routes>
              <Route path="/" element={<Auth />} />
              <Route path="/accounts" element={<AccountList />} />
              <Route path="/account/:id" element={<AccountDetails />} />
              <Route path="/app/:id" element={<ClientDetails />} />
              {/*<Route path="/test" element={<Map/>}/>*/}
              <Route path="*" element={<NotFound />} />
          </Routes>
      </Main>
    );
}

export default App;
