import axios from "axios";
import {Account} from "@typings/index";
import {API, getHeaders} from "./api";


export function getAccountList() {
    return axios
        .get(`${API}/auth/account`, {headers: getHeaders()})
        .then(response => response.data)
}


export function getAccountDetails(account_id: string) {
    return axios
        .get(`${API}/auth/account/${account_id}`, {headers: getHeaders()})
        .then(response => response.data)
}


export function createAccount(account: Account) {
    return axios
        .post(`${API}/auth/account`, account, {headers: getHeaders()})
        .then(response => response.data)
}


export function updateAccount(accountId: number, newAccount: Account) {
    return axios
        .post(`${API}/auth/account/${accountId}`, newAccount, {headers: getHeaders()})
        .then(response => response.data)
}


export function removeAccount(account_id: number) {
    return axios
        .delete(`${API}/auth/account/${account_id}`, {headers: getHeaders()})
        .then(response => response.data)
}


export function getAccountStats(account_id: number, month: number, year:number=2023) {
    return axios
        .get(`${API}/auth/account/${account_id}/stats?month=${month}&year=${year}`, {headers: getHeaders(), timeout: 120000})
        .then(response => response.data)
}
