import axios from "axios";
import {API, getHeaders} from "./api";

export type SystemPermissions = {
    code: string;
    description: string;
    request_method: "GET" | "POST" | "PUT" | "DELETE",
    active: boolean;
    id: number;
    user_default: boolean;
    request_path: string
}


export function getAvailableClientPermissions() {
    return axios
        .get(`${API}/auth/perms`, {headers: getHeaders()})
        .then(response => response.data)
}

