import React, {useContext, useState} from 'react';
import {RootContext} from '../../context/rootContext';
import {loginUser, UserLoginParams, UserLoginSuccess} from "../../services/UserService";
import {Button, Form, Input, message, Spin} from 'antd';
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import {AxiosError} from "axios";


export default function Auth() {
    const {authenticate} = useContext(RootContext);
    const [loading, setLoading] = useState(false)
    const onFinish = (user: UserLoginParams) => {
        setLoading(true);
        loginUser(user)
            .then(
                (success: UserLoginSuccess) => {
                    authenticate(success);
                },
                (error: AxiosError) => {
                    console.log("cannot login atm", error.response.data)
                    // @ts-ignore
                    message.error(error.response.data.message || 'Błąd logowania');
                }
            )
            .finally(() => setLoading(false))
    };

    return (
        <div className="Auth-form-container">
            <Spin tip="Loading..." spinning={loading}>
                <div className="Auth-form">
                    <Form
                        name="normal_login"
                        className="login-form m-4"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                    >
                        <h1 className="h3 mb-3 fw-normal">Logowanie</h1>
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'Email jest niepoprawny',
                                },
                                {
                                    required: true,
                                    message: 'Wprowadź email',
                                },
                            ]}
                        >
                            <Input size={"large"} prefix={<UserOutlined className="site-form-item-icon"/>}
                                   placeholder="email"/>
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Wprowadź hasło',
                                },
                            ]}
                        >
                            <Input size={"large"}
                                   prefix={<LockOutlined className="site-form-item-icon"/>}
                                   type="password"
                                   placeholder="hasło"
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" block>
                                Zaloguj
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Spin>
        </div>
    );
}