import React, {FormEvent, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {Button, Checkbox, Form, Input, message, Space, Tabs, Typography} from 'antd';
import {LockOutlined, SaveOutlined, UnlockOutlined} from '@ant-design/icons';
import {AxiosError} from "axios";
import {getClientDetails, updateClient} from "../../../services/UserService";
import {AppTokenList} from "./token";
import {Client} from "@typings/index";
import {EditUserPermissions} from "./permissions";

export function ClientDetails() {
    const {id} = useParams();
    const [client, setClient] = useState<Client | undefined>(undefined)
    const [editClient, setEditClient] = useState<Client | undefined>(undefined)
    const [editMode, setEditMode] = useState(false)
    const [loading, setLoading] = useState<boolean>(true);

    function getTitle(client: Client) {
        switch (client.client_type) {
            case "user":
                return `Edycja użytkownika ${client.name}`
            case "application":
                return `Edycja aplikacji ${client.name}`
        }
    }

    function getLabel(client: Client) {
        switch (client.client_type) {
            case "user":
                return `Nazwa użytkownika`
            case "application":
                return `Nazwa aplikacji`
        }
    }

    function updateClientDetails() {
        setLoading(true);
        getClientDetails(id)
            .then(
                (success: Client) => {
                    console.log("get client details response", success);
                    setClient(success);
                    setEditClient({
                        name: success.name,
                        client_type: success.client_type,
                        email: success.email,
                        active: success.active
                    });
                },
                (error: AxiosError) => {
                    console.log("cannot get client details", error.response.data)
                }
            )
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        if (id) {
            updateClientDetails();
        }
    }, [id])

    const onEditMode = () => {
        setEditMode(!editMode)
    }

    function onFormChange(event: FormEvent<HTMLFormElement>) {
        const {name, value, checked, type} = event.target as HTMLInputElement
        setEditClient({
            ...editClient,
            [name]: type === "checkbox" ? checked : value
        })
    }

    const onAccountSave = (e: React.MouseEvent) => {
        setLoading(true)
        e.preventDefault();
        updateClient(client.id, editClient)
            .then(
                (success: Client) => {
                    console.log("update client", success);
                    setClient(success)
                    message.success('Pomyślnie zaktualizowano');
                },
                (error: AxiosError) => {
                    console.log("cannot update client", error.response.data)
                    // @ts-ignore
                    message.error(error.response.data.message || 'Błąd aktualizacji danych');
                    updateClientDetails()
                }
            )
            .finally(() => {
                setLoading(false)
                setEditMode(false);
            });
    };

    return client && <>
      <Form layout={"horizontal"} disabled={!editMode} onChange={onFormChange}>
        <Typography.Title level={2}>
            {getTitle(client)}
        </Typography.Title>
        <Form.Item label={getLabel(editClient)} labelCol={{span: 4}} wrapperCol={{span: 14}}>
          <Input name={"name"} value={editClient.name}/>
        </Form.Item>
          {editClient.client_type === 'user' &&
            <Form.Item label={"Email"} labelCol={{span: 4}} wrapperCol={{span: 14}}>
              <Input name={"email"} value={editClient.email}/>
            </Form.Item>
          }
        <Form.Item label="Aktywne" labelCol={{span: 4}} wrapperCol={{span: 14}}>
          <Checkbox name={"active"} checked={editClient.active}/>
        </Form.Item>
        <Form.Item wrapperCol={{span: 4, offset: 4}}>
          <Space>
              {!editMode &&
                <Button size={"small"} disabled={false} onClick={onEditMode}
                        icon={<UnlockOutlined/>} type="primary">
                  Edytuj dane
                </Button>}
              {editMode &&
                <>
                  <Button size={"small"} icon={<LockOutlined/>} type="primary" onClick={onEditMode}>Anuluj</Button>
                  <Button size={"small"} icon={<SaveOutlined/>} type="primary" onClick={onAccountSave}>Zapisz</Button>
                </>
              }
          </Space>
        </Form.Item>
      </Form>

      <Tabs
        type="card"
        items={[
            {
                label: "Uprawnienia",
                key: "client-permissions",
                children: <EditUserPermissions client={client} updateClientDetails={updateClientDetails}/>,
            },
            {
                label: "Klucze API",
                key: "client-tokens",
                children: <AppTokenList client={client} updateClientDetails={updateClientDetails}/>,
            }

        ]}
      />
    </>
};