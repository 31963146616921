import {Button, Col, message, Popconfirm, Row, Space, Table, Typography} from 'antd';
import React, {useState} from 'react';
import {AppToken, Client} from "@typings/index";
import {PlusOutlined} from "@ant-design/icons";
import {revokeClientToken} from "../../../../services/UserService";
import {AxiosError} from "axios";
import {CreateAppTokenModal} from "./CreateAppTokenModal";

interface TokenListProps {
    updateClientDetails: () => void;
    client: Client;
}

export function AppTokenList(props: TokenListProps) {
    const [createTokenModalOpen, setCreateTokenModalOpen] = useState(false);
    const [loading, setLoading] = useState<boolean>(true);


    const openCreateTokenModal = () => {
        setCreateTokenModalOpen(true);
    }

    const closeCreateTokenModal = () => {
        props.updateClientDetails();
        setCreateTokenModalOpen(false);
    }

    const revokeToken = (e: React.MouseEvent<HTMLElement>, token: AppToken) => {
        e.preventDefault();
        setLoading(true);
        revokeClientToken(token.id)
            .then(
                (success) => {
                    console.log("revokeClientToken response", success);
                    props.updateClientDetails();
                    message.success('Pomyślnie unieważniono');
                },
                (error: AxiosError) => {
                    console.log("cannot revokeClientToken", error.response.data)
                    message.error('Błąd operacji');
                }
            )
            .finally(() => setLoading(false));
    };

    return props.client.token_list && (
        <>
            <Row justify="space-between" align="middle" style={{marginBottom: 16}}>
                <Col span={9}>
                    <Space>
                        <Typography.Title level={3} style={{marginBottom: 0}}>
                            Lista wygenerowanych tokenów
                        </Typography.Title>
                    </Space>
                </Col>
                <Col span={3}>
                    <Button className="link-primary m-1" icon={<PlusOutlined/>} onClick={openCreateTokenModal}
                            style={{float: "right"}}>
                        Dodaj
                    </Button>
                </Col>
            </Row>
            <Table
                key={"token-list-table"}
                size={"small"}
                pagination={false}
                scroll={{y: 500}}
                rowKey={record => `token-${record.id}`}
                locale={{emptyText: 'Brak danych'}}
                columns={[
                    {
                        title: 'Nazwa',
                        dataIndex: 'name',
                        key: 'name',
                    },
                    {
                        title: 'Wygenerowany',
                        dataIndex: 'create_date',
                        key: 'create_date',
                        render: (_value: any, record: AppToken, _index) => <>
                            {record.create_date}
                        </>,
                    },
                    {
                        title: 'Ważny do',
                        dataIndex: 'valid_to',
                        key: 'valid_to',
                        render: (_value: any, record: AppToken, _index) => <>
                            {record.valid_to ?? "-"}
                        </>,
                    },
                    {
                        title: '',
                        dataIndex: '',
                        key: 'x',
                        render: (_value: any, record: AppToken, _index) => <Popconfirm
                            title="Czy na pewno?"
                            description="Operacji nie można cofnąć"
                            onConfirm={e => revokeToken(e, record)}
                            okText="Tak"
                            cancelText="Nie"
                        >
                            <Button disabled={record.revoked} className="link-primary m-1">
                                {/*onClick={e => revokeToken(e, record)}>*/}
                                Unieważnij</Button>
                        </Popconfirm>,
                    },
                ]}
                dataSource={[...props.client.token_list]}
            />

            {createTokenModalOpen &&
              <CreateAppTokenModal open={createTokenModalOpen} onClose={closeCreateTokenModal} client={props.client}/>}
        </>
    );
}