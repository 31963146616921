import React, {FormEvent, useEffect, useState} from 'react';
import {Account} from '../../types';
import {useParams} from "react-router-dom";
import {Button, Checkbox, Form, Input, message, Space, Tabs, Typography} from 'antd';
import {LockOutlined, SaveOutlined, UnlockOutlined} from '@ant-design/icons';
import {getAccountDetails, updateAccount} from "../../services/AccountService";
import {AxiosError} from "axios";
import {ClientList} from "./client";
import {AccountApiStats} from "./stats/AccountApiStats";


export default function AccountDetails() {
    const {id} = useParams();
    const [account, setAccount] = useState<Account | undefined>(undefined)
    const [editAccount, setEditAccount] = useState<Account | undefined>(undefined)
    const [editMode, setEditMode] = useState(false)
    const [loading, setLoading] = useState<boolean>(true);

    function updateAccountDetails() {
        getAccountDetails(id)
            .then(
                (success: Account) => {
                    console.log("get account details response", success);
                    setAccount(success);
                    setEditAccount({
                        name: success.name,
                        full_name: success.full_name,
                        active: success.active
                    });
                },
                (error: AxiosError) => {
                    console.log("cannot get account details", error.response.data)
                }
            )
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        if (id) {
            updateAccountDetails();
        }
    }, [id])

    const onEditMode = () => {
        setEditMode(!editMode)
    }

    function onFormChange(event: FormEvent<HTMLFormElement>) {
        const {name, value, checked, type} = event.target as HTMLInputElement
        setEditAccount({
            ...editAccount,
            [name]: type === "checkbox" ? checked : value
        })
    }

    const onAccountSave = (e: React.MouseEvent) => {
        setLoading(true)
        e.preventDefault();
        updateAccount(account.id, editAccount)
            .then(
                (success: Account) => {
                    console.log("updateAccount", success);
                    setAccount(success)
                    message.success('Pomyślnie zaktualizowano');
                },
                (error: AxiosError) => {
                    console.log("cannot update account", error.response.data)
                    // @ts-ignore
                    message.error(error.response.data.message || 'Błąd aktualizacji danych');
                    updateAccountDetails();
                }
            )
            .finally(() => {
                setLoading(false)
                setEditMode(false);
            });
    };

    const tabItems = () => {
        return [
            {
                label: "Aplikacje",
                key: "account-apps",
                children: <ClientList account={account} refreshAccountDetails={() => updateAccountDetails()}
                                      client_type={"application"}/>,
            },
            {
                label: "Użytkownicy",
                key: "account-users",
                children: <ClientList account={account} refreshAccountDetails={() => updateAccountDetails()}
                                      client_type={"user"}/>,
            },
            {
                label: "Konto",
                key: "account-details",
                children: <div>Szczegóły rozliczeniowe konta poniżej</div>,
            },
            {
                label: "Statystyki zapytań",
                key: "account-usage",
                children: <AccountApiStats account={account}/>,
            }
        ]
    }

    return account && <>
      <Form layout={"horizontal"} disabled={!editMode} onChange={onFormChange}>
        <Typography.Title level={2}>
          Edycja konta {account.name}
        </Typography.Title>
        <Form.Item label="Nazwa skrócona" labelCol={{span: 4}} wrapperCol={{span: 14}}>
          <Input name={"name"} value={editAccount.name}/>
        </Form.Item>
        <Form.Item label="Pełna nazwa" labelCol={{span: 4}} wrapperCol={{span: 14}}>
          <Input name={"full_name"} value={editAccount.full_name}/>
        </Form.Item>
        <Form.Item label="Aktywne" labelCol={{span: 4}} wrapperCol={{span: 14}}>
          <Checkbox name={"active"} checked={editAccount.active}/>
        </Form.Item>
        <Form.Item wrapperCol={{span: 4, offset: 4}}>
          <Space>
              {!editMode &&
                <Button size={"small"} disabled={false} onClick={onEditMode} icon={<UnlockOutlined/>} type="primary">
                  Edytuj dane
                </Button>
              }
              {editMode &&
                <>
                  <Button size={"small"} icon={<LockOutlined/>} type="primary" onClick={onEditMode}>Anuluj</Button>
                  <Button size={"small"} icon={<SaveOutlined/>} type="primary" onClick={onAccountSave}>Zapisz</Button>
                </>
              }
          </Space>
        </Form.Item>
      </Form>
      <Tabs type="card" items={tabItems()}/>
    </>
};