import axios from "axios";
import {API, getHeaders} from "./api";
import {Client} from "@typings/index";

export type UserLoginParams = {
    email: string;
    password: string
}
export type UserLoginSuccess = {
    access_token: string;
    refresh_token: string;
    valid_time: number;
}

export function loginUser(params: UserLoginParams) {
    return axios
        .post(`${API}/auth/user`, params)
        .then(response => response.data)
}


export function createClient(client: Client) {
    return axios
        .put(`${API}/auth/user`, client, {headers: getHeaders()})
        .then(response => response.data)
}


export function getClientDetails(client_id: string) {
    return axios
        .get(`${API}/auth/user/${client_id}`, {headers: getHeaders()})
        .then(response => response.data)
}


export function removeClient(client_id: number) {
    return axios
        .delete(`${API}/auth/user/${client_id}`, {headers: getHeaders()})
        .then(response => response.data)
}


export function generateClientToken(name: string, owner_id: number, valid_h:number = null) {
    const body = {name, owner_id, duration_h: valid_h}
    return axios
        .put(`${API}/auth/api_token`, body, {headers: getHeaders()})
        .then(response => response.data)
}


export function revokeClientToken(token_id: number) {
    return axios
        .delete(`${API}/auth/api_token/${token_id}`, {headers: getHeaders()})
        .then(response => response.data)
}


export function updateClient(clientId:number, client: Client) {
    return axios
        .post(`${API}/auth/user/${clientId}`, client, {headers: getHeaders()})
        .then(response => response.data)
}


export function updateClientPermission(clientId:number, client: Client) {
    return axios
        .post(`${API}/auth/user/${clientId}/perms`, client, {headers: getHeaders()})
        .then(response => response.data)
}