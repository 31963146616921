import {Button, Checkbox, Table} from 'antd';
import React, {MouseEvent, useState} from 'react';
import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {Account, Client} from "@typings/index";
import {AddClientModal} from "./AddClientModal";
import {RemoveClientModal} from "./RemoveClientModal";

interface ClientListProps {
    account: Account
    refreshAccountDetails: () => void
    client_type: "user" | "application"
}

export function ClientList(props: ClientListProps) {
    const navigate = useNavigate();
    const [createNewClientModalOpen, setCreateNewClientModalOpen] = useState(false);
    const [removeClientModalOpen, setRemoveClientModalOpen] = useState(false);
    const [selectedClient, setSelectedClient] = useState(undefined);

    const openNewClientModal = () => {
        setCreateNewClientModalOpen(true);
    }

    const closeNewClientModal = () => {
        props.refreshAccountDetails();
        setCreateNewClientModalOpen(false);
    }

    const openRemoveClientModal = (e: any, client: Client) => {
        e.preventDefault();
        setSelectedClient(client);
        setRemoveClientModalOpen(true);
    };

    const closeRemoveClientModal = () => {
        props.refreshAccountDetails();
        setRemoveClientModalOpen(false);
        setSelectedClient(undefined);
    }

    const openEditClientModal = (e: any, client: Client) => {
        e.preventDefault();
        navigate(`/app/${client.id}`)
    };

    function getColumns() {
        switch (props.client_type) {
            case "user":
                return [
                    {
                        title: 'Nazwa użytkownika',
                        dataIndex: 'name',
                        key: 'name',
                    },
                    {
                        title: 'Email',
                        dataIndex: 'email',
                        key: 'email',
                    },
                    {
                        title: 'Aktywny',
                        dataIndex: 'active',
                        key: 'active',
                        render: (_value: any, client: Client, _index) => <Checkbox style={{color: "red"}}
                                                                                   checked={client.active}/>,
                    },
                    {
                        title: '',
                        dataIndex: '',
                        key: 'x',
                        render: (_value: any, client: Client, _index) => <>
                            <Button className="link-primary m-1" icon={<EditOutlined/>}
                                    onClick={e => openEditClientModal(e, client)}/>
                            <Button className="link-danger m-1" icon={<DeleteOutlined/>}
                                    onClick={e => openRemoveClientModal(e, client)}/>
                        </>,
                    },
                ]
            case "application":
                return [
                    {
                        title: 'Nazwa aplikacji',
                        dataIndex: 'name',
                        key: 'name',
                    },
                    {
                        title: 'Aktywna',
                        dataIndex: 'active',
                        key: 'active',
                        render: (_value: any, client: Client, _index) => <Checkbox style={{color: "red"}}
                                                                                   checked={client.active}/>,
                    },
                    {
                        title: '',
                        dataIndex: '',
                        key: 'x',
                        render: (_value: any, client: Client, _index) => <>
                            <Button className="link-primary m-1" icon={<EditOutlined/>}
                                    onClick={e => openEditClientModal(e, client)}/>
                            <Button className="link-danger m-1" icon={<DeleteOutlined/>} contextMenu={"asd"}
                                    onClick={e => openRemoveClientModal(e, client)}/>
                        </>,
                    },
                ]

        }
    }

    function getData() {
        switch (props.client_type) {
            case "user":
                return [...props.account.users];
            case "application":
                return [...props.account.applications];

        }
    }

    return (
        <>
            <Table
                pagination={false}
                size={"small"}
                rowKey={record => `account-${record.id}`}
                key={"app-list-table"}
                locale={{emptyText: 'Brak danych'}}
                columns={getColumns()}
                dataSource={getData()}
            />
            <Button className="link-primary m-1" icon={<PlusOutlined/>} onClick={openNewClientModal}
                    style={{float: "right",}}>
                Dodaj
            </Button>
            {createNewClientModalOpen &&
              <AddClientModal open={createNewClientModalOpen} onClose={closeNewClientModal}
                              account={props.account} clientType={props.client_type}/>}
            {removeClientModalOpen &&
              <RemoveClientModal open={removeClientModalOpen} onClose={closeRemoveClientModal}
                                 client={selectedClient}/>}
        </>
    );
}
